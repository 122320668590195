$grid-column-width: 26px;
$grid-row-height: 400px;
$grid-gap: 5px;

// Fonts
$gb: "Soho Gothic W02 Bold";
$g: "Soho Gothic W02 Regular";
$sb: 'Soho W02 Bold';
$s: 'Soho W02 Regular';


// Colors
$main: #C70282;
$black: #000101;
$medium-grey: #606060;
$light-grey: #707070;
$grey: #393D3E;
$dark-grey: #2C3133;
$blue: #4091AD;
$bright-grey: #f0f0f0;
$warm-grey: #6B6867;
$warm-greyP5: #7E7B78;

$font-sizes: (1rem, 1.61rem, 2.59rem, 4.17rem);

h1,h2 {
  font-family: $g;
  font-size: nth($font-sizes, 3);
  @media (max-width: 600px) {
    font-size: 1.61rem; } }

h3 {
  font-size: nth($font-sizes, 2);
  @media (max-width: 760px) {
    font-size: 1.4rem; } }

p, a, li, span, strong, b, em {
  font-size: nth($font-sizes, 1);
  line-height: 1.8; }

span, strong, b, em {
  font-size: inherit; }




