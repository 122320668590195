.border--box {
    //border: 1px solid white
    padding: 2em 1.61em;
    background: white;
    color: $black;
    margin: 0;
    h3 {
        font-family: $gb;
        margin-bottom: 1em; } }

.box--card {
    background: white;
    color: $black;
    margin: 1em;
    padding: 1.6em;
    ul {
        margin-top: 1em;
        li {
            margin-left: 20px;
            position: relative;
            margin-bottom: 0.5em;
            &:before {
                content: "•";
                position: absolute;
                left: -20px;
                top: 0;
                color: $main; } } }
    .partner--row {
        margin-bottom: 0;
        padding-bottom: 0;
        img {
            margin-bottom: 0; } }
    img {
        margin-bottom: 1em; }
    h3 {
        font-family: $gb;
        margin-bottom: 0.6em; }
    @media (min-width: 760px) {
        &:first-child {
            margin-left: 0; }
        &:last-child {
            margin-right: 0; } }
    @media (max-width: 760px) {
        margin: 0 -1em 1em -1em;
        padding: 1em; } }
