@import 'legal';

#intro {
    h2 {
        font-size: 1.4rem;
        font-family: $g;
        @media (max-width: 740px) {
            font-size: 1rem; } }
    h1 {
        text-transform: uppercase; }
    #keyvisual {
        left: 20px;
        position: relative;
        z-index: 10;
        pointer-events: none;
        @media (max-width: 740px) {
            left: -20px; } } }
.partner--row {
    align-items: center;
    flex-wrap: nowrap;
    height: auto;
    @media (max-width: 740px) {
        padding-top: 0; }
    img {
        width: 120px;
        margin-right: 1em; }
    p {
        // padding-left: 1em
        // border-left: 1px solid #ffffff
        display: block;
        line-height: 1.2;
        font-size: 0.825rem;
        align-items: center; }
    strong {
        font-family: $gb; } }

#kampagne {
    //background: linear-gradient(to top, rgba($black, 0), $black 30%, $black 70%, rgba($black, 0))
    //background: $black
    .soft--row {
        align-items: stretch; }
    .boxed--content {
        h2 {
            //font-size: nth($font-sizes, 3)
            margin-bottom: 0.5em;
            max-width: 800px; }
        > p {
            max-width: 800px; } }

    .orchester--image {
        width: 100%;

        background: url('../img/streicher.jpg') center;
        background-size: cover;
        overflow: hidden;
        //margin-left: 2em
        //border-radius: 50%
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transform: translateY(-100%);
            transition: transform 1s ease-in 500ms; } }
    &.out {
        .orchester--image {
            img {
                transform: translateY(0);
                transition: transform 200ms ease; } } }
    .order-1 {
        display: flex;
        justify-content: center; }
    @media (max-width: 740px) {

        .order-1 {
            width: 100%;
            justify-content: center;
            min-height: 50vw; } }
    @media (max-width: 540px) {
        .soft--row {
            // margin-left: -1em
 }            // margin-right: -1em
        .order-1 {
            min-height: 70vw; }
        h3 {
            font-size: 1.2rem; } } }

#mitmachen {
    .info--box {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 2em 0;
        .info--box-content {
            flex: 1 0 55%;
            padding: 1.6em 2em;
            background: #fff;
            color: $black;
            h3 {
                font-family: $gb;
                margin-bottom: 0.5em; } }
        .info--box-image {
            position: relative;
            flex: 1 0 40%;
            min-width: 280px;


            img {
                max-width: 100%;
                display: block; } }
        @media (max-width: 540px) {
            margin-left: -1em;
            margin-right: -1em; } }
    #teilnahme {
        @media (max-width: 640px) {
            margin: 0 -1em 0 -1em!important;
            .input--group.slide--right {
                margin-left: 1em;
                margin-right: 1em; } } }
    .marketing--selection {
        background: #fff;
        padding: 1em;
        color: $black;
        .input--group {
            margin: 0; }
        hr {
            border: none;
            height: 1px;
            margin: 1em 0;
            background: #ececec; } }
    .product--table {

        width: 100%;
        margin: 2em 0;
        .product--table-head, .product--table-row {
            display: flex;
            @media (max-width: 1040px) {
              flex-wrap: wrap; } }

        .product--table-head {
            background: grey;
            @media (max-width: 1040px) {
                display: none; } }
        .table--item {
            flex: 3 0 0;
            padding: 0.5em 1em;
            @media (max-width: 640px) {
                padding: 0.5em 0; }

            &:last-child {
                width: 80px;
                flex: 1 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right; } }
        .product--table-row {
            background: #fff;
            color: $black;
            position: relative;
            &:nth-child(odd) {
                background: #ececec; }
            @media (max-width: 1040px) {
                padding: 1em;
                //padding-right: 120px
                .table--item {
                    flex: 1 0 35%;
                    min-width: 200px;
                    input, select {
                        width: 100%; }
                    &:last-child {
                        flex: 1 0 100%;
                        // position: absolute
                        // right: 0
                        // top: 50%
 } } } } }                        // transform: translateY(-50%)
    #add-row {
        appearance: none;
        border: none;
        outline: none;
        color: white;
        background: $main;
        display: inline-block;
        text-align: center;
        width: 26px;
        height: 26px;
        max-width: 26px;
        min-width: 26px;
        max-height: 26px;
        min-height: 26px;
        cursor: pointer;
        transition: all 200ms ease;
        margin-left: 0.5em;
        border-radius: 4px;
        &:hover {
            background: lighten($main, 10%); } }
    #delete-row {
        appearance: none;
        border: none;
        outline: none;
        color: white;
        background: $medium-grey;
        font-size: 0.825rem;
        display: inline-block;
        text-align: center;
        width: 26px;
        height: 26px;
        max-width: 26px;
        min-width: 26px;
        max-height: 26px;
        min-height: 26px;
        cursor: pointer;
        transition: all 200ms ease;
        border-radius: 4px;
        position: relative;
        img {
            height: 1em;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%); }


        &:hover {
            background: lighten($medium-grey, 10%); } }
    #error {
        h4 {
            margin-bottom: 2em;
            color: white;
            display: block;
            padding: 0.3em 0.6em;
            background: $main; } }
    .input--group {
        margin: 1em 0;
        label {
            margin-left: 10px; } }
    .form--personal {
        display: flex;
        flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 1.6em;
        padding-right: 0.6em;
        background: #fff;

        > input {
            flex: 1 0 45%;
            min-width: 200px;
            margin: 0 1em 1em 0;
            padding: 0.3em 0.6em; }
        .form--footer {
            color: $black;
            label {
                margin-bottom: 1.6em; } } } }
select {
    &.disabled {
        opacity: 0.6;
        pointer-events: none; } }

#opns {
    h2 {
        margin-bottom: 1em;
        margin-top: 1em; }
    ul {
        li {
            margin-left: 20px;
            position: relative;
            margin-bottom: 0.5em;
            &:before {
                content: "•";
                position: absolute;
                left: -20px;
                top: 0;
                color: $main; } } } }
