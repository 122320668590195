#klavier {
    width: 100%;
    padding: 6em 2em;
    overflow-x: hidden;
    text-align: center;
    .sucess--message {
        p {
            display: block;
            margin: 2em auto;
            padding: 2em;
            background: $main;
            color: white;
            font-size: 1.2rem;
            font-family: $s; } }
    .tooltip {
        color: white;
        text-align: center;
        position: relative;
        z-index: 10;
        margin-top: 10px;
        font-size: 0.825em;
        font-family: $gb;
        position: absolute;
        left: 50%;
        bottom: 1em;
        text-transform: uppercase;
        transform: translateX(-50%); }
    .key--tipp {
        z-index: 9;
        color: white;
        position: relative;
        margin-top: 10px;
        font-size: 0.825em;
        cursor: pointer;
        display: inline-block;
        span {
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: $main;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px; } }
    h1 {
        margin: 40px auto;
        z-index: 1;
        position: relative; }
    >p {
        margin: 0 auto;
        z-index: 1;
        position: relative; }

    #klavier--form {
        display: block;
        margin-top: 70px;
        text-align: center;

        .input--group {
            display: flex;
            position: relative;
            z-index: 2;
            justify-content: center;
            flex-wrap: wrap;
            h3 {
                display: block;
                width: 100%;
                margin-bottom: 10px; }
            p {
                color: white;
                display: inline-block;
                margin-right: 10px; }
            input {
                margin-right: 5px;
                border-radius: 4px;
                border: none;
                outline: none;
                box-shadow: inset -1px -1px 5px rgba(black, 0.5);
                width: 30px;
                color: black;
                text-align: center;
                font-size: nth($font-sizes, 1);
                &:disabled {
                    background: #fff; } }
            #resetButton {
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 4px;
                outline: none;
                border: 1px solid $main;
                margin-left: 0;
                background: radial-gradient(rgba(white, 0) 20%, rgba(darken($main, 30%), 0.2)), linear-gradient(45deg, $main 49%, lighten($main, 30%) 50%, $main);
                position: relative;
                cursor: pointer;
                i {
                    width: 15px;
                    height: 15px;
                    background: url('../img/icn--reset.svg') center no-repeat;
                    background-size: contain;
                    position: absolute;
                    left: 50%;
                    top: 48%;
                    transform: translate(-50%,-50%); } }
            .form--button {
                width: 100%;
                margin: 20px 0;
                button {
                    color: $main;
                    font-size: nth($font-sizes, 1);
                    border-radius: 40px;
                    padding: 0.6em 1.6em;
                    border: 1px solid $main;
                    line-height: 1;
                    text-transform: uppercase;
                    font-family: $gb;
                    background: white;
                    cursor: pointer; } } }





        .klavier--tasten-grid {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(42, $grid-column-width);
            grid-template-rows: $grid-row-height;
            position: relative;
            margin: 80px auto 0;
            -ms-flex-line-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            grid-gap: $grid-gap;
            z-index: 0;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                width: calc(42 * 26px); }
            @media (max-width: 1340px) {
                grid-template-columns: repeat(42, $grid-column-width * 0.75);
                grid-template-rows: $grid-row-height * 0.75;
                grid-gap: $grid-gap * 0.75;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: calc(42 * (26px * 0.75)); } }
            @media (max-width: 1024px) {
                grid-template-columns: repeat(42, $grid-column-width * 0.6);
                grid-template-rows: $grid-row-height * 0.6;
                grid-gap: $grid-gap * 0.6;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: calc(42 * (26px * 0.6)); } }

            @media (max-width: 780px) {
                grid-template-columns: repeat(21, $grid-column-width * 0.75);
                grid-template-rows: $grid-row-height * 0.75;
                grid-gap: $grid-gap * 0.75;
                .octave2 {
                    display: none!important; }
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: calc(21 * (26px * 0.75)); } }
            @media (max-width: 560px) {
                grid-template-columns: repeat(21, $grid-column-width * 0.6);
                grid-template-rows: $grid-row-height * 0.6;
                grid-gap: $grid-gap * 0.6;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: calc(21 * (26px * 0.6)); } }
            @media (max-width: 400px) {
                grid-template-columns: repeat(21, $grid-column-width * 0.4);
                grid-template-rows: $grid-row-height * 0.4;
                grid-gap: $grid-gap * 0.4;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: calc(21 * (26px * 0.4)); } }

            .klavier--taste-full {
                transform-origin: top center;
                display: block;
                grid-row: 1;
                background: #ccc;
                border: none;
                border-radius: $grid-gap * 2;
                position: relative;
                height: 100%;
                transition: all 50ms ease;
                cursor: pointer;
                @media (max-width: 740px) {
                    border-radius: $grid-gap * 0.75 *2; }
                @media (max-width: 540px) {
                    border-radius: $grid-gap * 0.6 *2; }

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    margin-right: 5px; }
                @for $i from 0 through 14 {
                    &:nth-child(#{$i + 1}) {
                        @if $i > 0 {
                            grid-column: ($i * 3 + 1) / span 3; }
                        @else {
                            grid-column: 1 / span 3; } } }
                .tooltip {
                    color: black; }




                &:after {
                    content: "";
                    background: linear-gradient(to top, #f0f0f0, #fff 70%, #aaa);
                    width: calc(100% - 6px);
                    height: calc(100% - 6px);
                    position: absolute;
                    left: 50%;
                    border-radius: 6px;
                    top: 0;
                    transform: translateX(-50%);
                    transition: all 50ms ease; }
                &:before {
                    content: "";
                    background: linear-gradient(to top, rgba(#f0f0f0, 0.15), rgba(#fff,0));
                    width: 100%;
                    height: 120px;
                    position: absolute;
                    left: 0;
                    top: -140px;
                    z-index: 0;
                    border-radius: 10px;
                    transition: all 50ms ease;
                    filter: blur(2px);
                    pointer-events: none; }

                &:active, &:focus, &.active {
                    background: darken(#ccc, 10%);
                    height: calc(100% - 5px);
                    transition: all 50ms ease;
                    &:after {
                        transition: all 50ms ease;
                        background: linear-gradient(to top, darken(#f0f0f0, 3%) 70%, lighten(#aaa, 5%));
                        height: calc(100% - 3px);
 } } }                        //border-radius: 4px

            .klavier--taste-half {
                transform-origin: top center;
                appearance: none;
                display: block;
                grid-row: 1;
                height: 50%;
                background: #555;
                position: relative;
                border: none;
                border-radius: 0 0 5px 5px;
                border: 6px solid #001010;
                z-index: 1;
                transition: all 50ms ease;
                cursor: pointer;
                border-top: 0;
                $grid-cells: (3,6,12,15,18,24,27,33,36,39);

                @each $grid in $grid-cells {
                    $i: 14 + index($grid-cells, $grid);
                    &:nth-child(#{$i}) {
                        grid-column: ($grid) / span 2; } }
                @media (max-width: 780px) {
                    border-width: 4px; }



                &:before {
                    content: "";
                    background: linear-gradient(to top, #111, #000101);
                    border: 8px solid #000101;
                    width: 100%;
                    height: 120px;
                    position: absolute;
                    left: -8px;
                    top: -150px;
                    z-index: 0;
                    border-radius: 4px;
                    transition: all 50ms ease;
                    filter: blur(2px);
                    pointer-events: none; }
                &:after {
                    content: "";
                    transition: all 50ms ease;
                    position: absolute;
                    width: calc(100% - 6px);
                    height: calc(100% - 12px);
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background-image: linear-gradient(to left, rgba(black, 0.4), rgba(#333, 0.1)), linear-gradient(-30deg, black 50%, #aaa 50%, #222);
                    border: 3px solid lighten(black, 30%);
                    border-right: 3px solid lighten(black, 10%);
                    border-top: none;
                    border-bottom: 12px solid #222;
                    border-radius: 4px; }

                &:active, &:focus, &.active {
                    background: #333;
                    transition: all 50ms ease;
                    &:after {
                        border-bottom: 3px solid #222;
                        transition: all 50ms ease;
                        height: calc(100% - 3px);
 } } } } } }                        //background: linear-gradient(-30deg, black 48%, darken(#aaa,10%) 48%, #222)

body.modal--active {
    height: 100vh;
    overflow: hidden; }
