#finder {
    width: 100%;
    margin: 0 auto;
    padding: 1em;
    max-width: 1400px;
    .bh-sl-container {
        //background: #fff
        //padding: 2em
        text-align: center;
        width: 100%;
        h1 {
            font-size: nth($font-sizes, 2);
            color: white;
            margin-bottom: 1em; } }
    #bh-sl-user-location {
        margin-bottom: 2em;


        #bh-sl-address {
            color: $black;
            padding: 0.3em 1em;
            display: block;
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            margin-bottom: 2em; } } }

#alert {
    div {
        background: $main;
        width: 100%;
        margin-top: 1em;
        padding: 1em 2em;
        color: white; } }

#bh-sl-map-container {
    * {
        color: $black; }
    background: #fff;
    //padding: 20px
    display: block;
    @media (min-width: 1200px) {
        display: flex; }

    #bh-sl-map {
        flex: 2 0 0;

        width: 100%;
        height: 0;
        padding-bottom: 62%;
        @media (min-width: 1200px) {
            height: auto;
            padding-bottom: 0; } }

    .bh-sl-loc-list {
        flex: 1 0 0;
        padding: 0 20px;
        min-width: 250px;
        height: 100%;
        overflow-y: auto;
        padding: 2em;
        h3 {
            font-size: nth($font-sizes, 2);
            text-align: left;
            margin-bottom: 2em; }
        .list-label {
            background: $main;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            color: white;
            display: inline-block;
            text-align: center;
            margin-right: 10px;
            line-height: 15px;
            b {
                font-weight: 700;
                font-size: 0.925rem;
                color: #fff;
                position: relative;
                vertical-align: middle;
                line-height: 22px; } }

        .list {
            li {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid #cdcdcd;
                background: transparent!important;
                text-align: left; }
            .loc-name {
                font-weight: 600; }
            a {
                color: $main; }
            .list-content {
                @media (min-width: 740px) and (max-width: 1200px) {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    div {
                        margin: 0 1em 1em 0;
                        &:first-child {
                            min-width: 100%; } } } } } } }
