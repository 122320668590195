#modal {
    position: fixed;
    background: white;
    max-width: 90vw;
    width: 800px;
    max-height: 90vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 300;
    display: none;

    //display: block
    padding: 2em;
    padding-bottom: 3em;
    box-shadow: 0 0 300vw 300vw rgba(black, 0.95);
    overflow-y: auto;
    @media (max-height: 900px) {
        top: 2em;
        transform: translate(-50%,0); }
    #code {
        display: block;
        padding: 0.3em 1em;
        background: #efefef;
        margin: 1em 0 2em;
        letter-spacing: 0.1em; }
    * {
        color: black; }
    input:not([type="checkbox"]), select {
        width: 100%;
        display: block;
        font-size: nth($font-sizes, 1);
        padding: 0.3em 0.6em;
        margin-bottom: 1em; }
    select {
        display: none; }
    option {
        all: unset;
        margin-bottom: 10px;
        display: block;
        padding: 0.6em;
        disabled {
            opacity: 0.5; } }
    .tnb {
        font-size: 0.725em;
        margin-bottom: 1em; }
    label {
        padding-left: 1.61em;
        position: relative;
        line-height: 1.4;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            width: 12px;
            height: 12px;
            //border-radius: 4px
            border: 1px solid #ccc; }
        a {
            line-height: 1.4;
            color: $main; }
        margin-bottom: 2em;
        display: block; }
    input[type="checkbox"] {
        display: none;
        &:invalid {
            ~ label:before {
 } }                //border-color: green
        &:checked {
            ~ label:before {
                border-color: $main;
                background: $main; }
            ~label:after {
                content: "";
                width: 3px;
                height: 7px;
                border-right: 2px solid white;
                border-bottom: 2px solid white;
                position: absolute;
                left: 4px;
                top: 4px;
                transform: rotate(45deg); } } }
    #emailHP {
        display: none; }

    #closeModal {
        display: inline-block;
        position: absolute;
        top: 1em;
        right: 1em;
        line-height: 1;
        cursor: pointer;
        font-size: nth($font-sizes, 2); } }

.custom-select {
    position: relative;
    * {
        font-size: nth($font-sizes, 1); }
    select {
        display: none; }
    .select-selected {
        background: #efefef;
        transition: 150ms;
        margin: .5em 0 1em;
        border: 1px solid #ccc;
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 100%;
            background: $main;
            border-radius: 0 4px 4px 0; }
        &:after {
            content: "";
            position: absolute;
            top: 14px;
            right: 0.6em;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-color: white transparent transparent transparent; }

        &.select-arrow-active {
            border-radius: 4px;
            &:after {
                border-color: transparent transparent #fff transparent;
                top: 7px; } } }
    .select-items div,.select-selected {
        color: #ffffff;
        padding: 0.3em 0.6em;
        // border: 1px solid transparent
        // border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent
        cursor: pointer; }
    .select-items {
        position: absolute;
        background-color: white;
        box-shadow: 0 0 10px rgba(black, 0.1);
        border: 1px solid #ccc;

        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        border-radius: 0 0 5px 5px;
        div {

            padding: 0.6em;
            &.disabled {
                pointer-events: none;
                color: #ccc!important; }

            color: #333;
            font-size: 0.825em; }

        div:hover, .same-as-selected {
            background-color: rgba(0, 0, 0, 0.1); } }
    .select-hide {
        display: none; } }

#error_message {
    strong {
        display: block;
        padding: 0.3em 1em;
        text-align: center;
        background: $main;
        color: white;
        margin: 2em 0; } }
