* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  font-size: 15px;
  @supports (-webkit-overflow-scrolling: touch) {
    h1, h2, h3, h4 {
      letter-spacing: -0.1rem; }
    strong {
      letter-spacing: -0.05rem; } } }

html, body {

  font-size: 100%;
  line-height: 1.4; }

body {
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden; }



.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }


ol {

  li {
    list-style: decimal;
    margin-left: 20px;
    margin-bottom: 1em;
    font-family: $gb;
    strong {
      font-family: $gb; } } }
