footer {
    width: 100%;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #333;
    background: $black;
    span {
        font-size: 0.825rem;
        display: flex;
        color: white;
        a {
            font-size: 0.825rem;
            margin-left: 2em; } }

    @media (max-width: 600px) {
        display: block;
        text-align: center;
        span {
            display: block;
            text-align: center;
            margin: 0 auto;
            a {
                display: block;
                margin: 10px 0; } } } }
