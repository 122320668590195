.btn {
    display: inline-block;
    padding: 0.6em 2em;
    background: $main;
    color: white!important;
    font-size: nth($font-sizes, 1);
    outline: none;
    border: 1px solid $main;
    border-radius: 2em;
    cursor: pointer;
    transition: all 300ms ease;
    &:hover {
        background: lighten($main, 10%); } }
.btn-white {
    @extend .btn;
    padding: 0.6em 2em;
    margin: 2em 0;
    background: #fff;
    color: $main!important;
    font-family: $gb;
    text-transform: uppercase;
    &:hover {
        background: $main;
        color: white!important; } }
