nav {
    padding: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    z-index: 20;
    height: auto;
    transition: 300ms ease;
    #logo {
        transition: 300ms ease;
        padding: 1em 2em;
        z-index: 26;
        img {
            transition: 300ms ease; } }
    #burger {
        width: 50px;
        align-self: stretch;
        background: $main;
        border-radius: 0 0 4px 4px;
        display: flex;
        display: none;
        justify-content: center;
        align-items: flex-end;
        padding: 1em 0.7em;
        height: 100%;
        margin-right: 2em;
        cursor: pointer;
        div {
            width: 100%;
            height: 18px;
            position: relative;
            margin-top: 1.2em;
            //background: red
            span {
                width: 80%;
                border-radius: 4px;
                height: 2px;
                background: #fff;
                position: absolute;
                right: 0;
                top: 50%;
                transform: rotate(0deg) translateY(-50%);
                transition: 200ms ease;
                &:first-child {
                    width: 100%;
                    transform: rotate(0deg) translateY(0);
                    top: 0;
                    left: 0; }
                &:last-child {
                    width: 100%;
                    transform: rotate(0deg) translateY(0);
                    top: calc(100% - 2px);
                    left: 0; } } }
        &:hover {
            span {
                width: 100%; } }
        @media (max-width: 670px) {
            display: flex; } }


    .main--nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 2em;
        @media (max-width: 670px) {
            position: absolute;
            display: block;
            z-index: 25;
            background: #000;
            right: 0;
            top: 100%;
            width: 100%;
            padding: 2em 0;
            margin-right: 0;
            height: 100vh;
            transform: translate(200vw);
            transition: transform 300ms ease;
            li {
                text-align: right;
                border-bottom: 1px solid #333;
                .main--nav-item {
                    margin-left: 0;
                    padding: 1em 2em;
                    &:after {
                        content: none; }
                    &:hover {
                        background: #333; } } } }
        &-item {
            color: white;
            position: relative;
            display: block;
            z-index: 10;
            padding: 0.3em 0;
            margin-left: 2em;
            transition: all 200ms ease;
            &:after {
                transition: all 200ms ease;
                content: "";
                width: 0%;
                height: 2px;
                background: $main;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%); }
            &:hover, &.active {
                &:after {
                    width: 100%; } } } }
    &.scrolled {
        background: $black;
        height: 60px;
        #logo {
            padding: 0.3em 2em;
            img {
                width: 100px; } } } }
@media (max-width: 670px) {
    body.active {
        overflow: hidden;
        height: 100vh;
        .main--nav {
            transform: translateX(0);
            display: block;
            padding-top: 8em;
            top: 0; }
        #burger {
            z-index: 26;
            div {
                span {
                    width: 0;
                    &:first-child {
                        width: 100%;
                        top: 50%;
                        left: 0;
                        right: auto;
                        bottom: auto;
                        transform: rotate(45deg) translateY(-50%); }
                    &:last-child {
                        width: 100%;
                        top: 50%;
                        left: 0;


                        bottom: auto;
                        transform: rotate(-45deg) translateY(-50%); } } } } } }
