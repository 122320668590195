.legal--section {
    width: 100%;
    text-align: center;
    padding: 2em;
    margin-bottom: 2em;
    padding-top: 6em;
    min-height: 90vh;
    color: white;
    .container {
        max-width: 800px;
        margin: 0 auto;
        h3 {
            margin-top: 3em;
            margin-bottom: 1em; } } }
