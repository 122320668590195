.main--section {
    padding: 2rem;
    padding-top: 80px;
    display: flex;
    align-items: center;
    min-height: 100vh;
    color: white;
    width: 100vw;
    overflow-x: hidden;
    transition: all 200ms ease {
        margin-bottom: 6em; }
    @media (max-width: 760px) {
        padding: 1rem; }
    @media (max-width: 940px), (max-height: 900px) {
        align-items: flex-start;
        padding-top: 8em; }
    .soft--row {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding: 2em 0;
        position: relative;
        @media (max-width: 740px) {
            flex-direction: column; }
        .col--1 {
            flex: 1 0 0;
            position: relative;
            img {
                max-width: 100%; } } } }
// .scrolled
//     .main--section
//         padding-top: 80px

.left {
    align-items: flex-start!important; }
.heading {
    margin: 0 0 2rem;
    padding-left: 1.5rem;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 2px;
        background: $main; }
    .upper {
        text-transform: uppercase; } }
.boxed--content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; }
.soft--row {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center; }
.col--2 {
    flex: 2 0 0; }
.col--1 {
    flex: 1 0 0; }
.col--full {
    flex: 1 0 100%; }
.stretch {
    align-items: stretch; }

.content--background-fixed {
    background: url('../img/bg--home.jpg') bottom center;
    background-size: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1; }

.mw600 {
    max-width: 600px; }

.koop--row {
    display: flex;
    align-items: center;
    margin: 2em 0;
    flex-wrap: wrap;
    justify-content: center;
    &-image {
        flex: 1 0 0;
        max-width: 240px!important;
        min-width: 100px;
        margin-right: 1em;
        margin: 1em; }
    &-text {
        flex: 2 0 0;
        padding: 1em;
        border-left: 1px solid white; }
    // @media (max-width: 740px)
    //     display: block
    //     text-align: center
    //     &-text
    //         padding: 0
 }    //         border-left: none

@media (max-width: 740px) {
    .order-1 {
        order: 1; }
    .order-2 {
        order: 2; } }

.arrow--down {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); }

.cta--container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #d7d6d1;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1em;
    &.cta--last {
        align-items: center;
        top: -60px;
        right: -60px;
        padding: 1em;
        @media (max-width: 940px) {
            top: -130px;
            right: 0; } }


    h5 {
        span {
            display: block;
            font-family: $gb;
            font-size: 1.2rem;
            line-height: 1.4; }
        font-size: 1rem;
        font-family: $g;
        color: $black;
        text-align: center; }
    .cta--image {
        position: absolute;
        width: 70%;
        left: 50%;
        transform: translateX(-50%) rotate(18deg);
        bottom: 0; }
    @media (max-width: 940px) {
        top: -40%;
        width: 160px;
        height: 160px;
        padding: 0.6em 1em;
        .cta--image {
            bottom: -40px; } }
    @media (max-width: 740px) {
        top: 0; } }
.cta--container-s {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: #d7d6d1;
    position: absolute;
    right: 1em;
    top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    color: $black;
    text-align: center; }

@media (min-width: 940px) {
    .slide--in {
        .slide--right {
            transform: translateX(0);
            opacity: 1;
            transition: all 600ms ease; }
        &.out {
            .slide--right {
                transform: translateX(30vw);
                opacity: 0; } }

        .slide--left {
            transform: translateX(0);
            opacity: 1;
            transition: all 600ms ease; }
        &.out {
            .slide--left {
                transform: translateX(-30vw);
                opacity: 0; } } } }
.legal {
    font-size: 0.725rem;
    font-style: normal; }

figure {
    position: relative;
    figcaption {
        position: absolute;
        bottom: 30px;
        left: 20px;
        color: white; } }
